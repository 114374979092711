<template>
	<div>
		<div>
		<p>项目id</p>
		<input type="text" v-model="projectId"/>
		</div>
		<div>
			<p>评审时间(时间戳)</p>
			<input type="text" v-model="reviewDatetime" />
		</div>
		<div>
		<p>机构id</p>
		<input type="text" v-model="orgId"/>
		</div>
		<div>
		<button @click="terminateProject()">废止项目</button>
		</div>
		<div>
			<button @click="orgShow()">显示机构占用情况</button>
		</div>
		<div>
			<p>{{orgExtracted}}</p>
		</div>
		<div>
			<button @click="projectShow()">显示项目占用情况</button>
		</div>

		<div>
			<p>{{projectExtracted}}</p>
		</div>
		<div><button @click="orgClear()">机构缓存清空</button></div>
		<div><button @click="projectClear()">项目缓存清空</button></div>
		<div><button @click="keyClear()">key缓存清空</button></div>
		<div><button @click="restoreSqlite()">恢复机构sqlite库</button></div>

        <div class="updateCache">
            <input type="text" placeholder="请输入openId" v-model="openId">
            <button @click="queryInfo">更新该用户缓存</button>
        </div>
	</div>
</template>

<script>
import global from "@/common/global"

export default{
	data(){
		return {
			projectId:'',
			orgId:'',
			orgExtracted:'',
			projectExtracted:'',
			reviewDatetime:0,
            openId: ''
		}
	},

	methods:{
		terminateProject:function(){
			this.$ajax({
				url: "/gateway/ed/extract/terminateProject",
				method: "post",
				params: {
				    projectId: this.projectId,
					orgId:this.orgId,
					reviewDatetime:this.reviewDatetime
				},
			}).then((res)=>{
				alert(JSON.stringify(res.data))
			}).catch((err)=>{
				alert(JSON.stringify(err))
			})
		},
		orgShow:function(){
			let __this = this
			this.$ajax({
				url: "/gateway/ed/extract/queryOrgExtractedExperts",
				method: "post",
				params: {
					orgId:this.orgId,
				},
			}).then((res)=>{
				__this.orgExtracted = JSON.stringify(res.data)
				// alert(JSON.stringify(res.data))
			}).catch((err)=>{
				alert(JSON.stringify(err))
			})
		},
		projectShow:function(){
			let __this = this
			this.$ajax({
				url: "/gateway/ed/extract/queryProjectExtracted",
				method: "post",
				params: {
				    projectId: this.projectId,
				},
			}).then((res)=>{
				__this.projectExtracted = JSON.stringify(res.data)
			}).catch((err)=>{
				alert(JSON.stringify(err))
			})
		},
		orgClear:function(){
			let __this = this
			this.$ajax({
				url: "/gateway/ed/extract/clearOrgCache",
				method: "post",
				params: {
				    orgId:this.orgId,
				},
			}).then((res)=>{
				__this.projectExtracted = JSON.stringify(res.data)
			}).catch((err)=>{
				alert(JSON.stringify(err))
			})
		},
		projectClear:function(){
			let __this = this
			this.$ajax({
				url: "/gateway/ed/extract/clearProject",
				method: "post",
				params: {
				    projectId:this.projectId,
				},
			}).then((res)=>{
				__this.projectExtracted = JSON.stringify(res.data)
			}).catch((err)=>{
				alert(JSON.stringify(err))
			})
		},
		keyClear:function(){
			let __this = this
			this.$ajax({
				url: "/gateway/ed/extract/clearKeyCache",
				method: "post",

			}).then((res)=>{
				__this.projectExtracted = JSON.stringify(res.data)
			}).catch((err)=>{
				alert(JSON.stringify(err))
			})
		},
		restoreSqlite:function(){
			let __this = this
			this.$ajax({
				url: "/gateway/ed/extract/sqlite/restore",
				method: "post",
				params: {
				    orgId:this.orgId,
				},
			}).then((res)=>{
				__this.projectExtracted = JSON.stringify(res.data)
			}).catch((err)=>{
				alert(JSON.stringify(err))
			})
		},

        /**
         * 根据 openId  更新缓存数据
         */
        queryInfo() {
            if (this.openId == '') {
                this.$toast({
                    message: '请输入openid',
                    type: 'fail',
                    className: 'div3'
                })
                return
            }
			//查询机构信息
			this.$ajax({
				url: "/gateway/ed/wx/wxEdExpertInfo/wxlogin",
			    method: "post",
			    params: {
			        openid: this.openId,
							appId: global.appid,
							appSecret: global.secret
			    },
			})
			.then((res)=>{
				//有用户和机构
				if(res.status=="200"&&res.data.org){
					//org
					this.$ls.set("edCurrentOrg",res.data.org)
					//扩展信息
					if(res.data.external){
						this.$ls.set("edCurrentOrgExternal",res.data.external)
					}
					//增值服务
					if(res.data.openRecord){
						this.$ls.set("edOpenRecord",res.data.openRecord)
					}
					//token
                    this.$ls.set("token",res.data.token)
                    this.$ls.set('openid', this.openId)
                    //当前用户信息
                    this.$ls.set("edCurrentUser",res.data.edCurrentUser)

                    this.$toast.success('更新缓存成功')


				}else{
                    // 未查询到机构或用户
                    this.$toast({
                        message: '未查询到用户或机构信息',
                        type: 'fail',
                    })

				}
			})
			.catch((err)=>{
				this.$toast("请求出错,请稍候重试！")
				return
			})
        }

	}
}
</script>

<style>
    .updateCache{
        margin-top: 0.5rem;
        padding: 0.3rem;

    }
    .updateCache input{
        width: 100%;
        border-bottom: 1px solid #bdc3c7;
    }

    button {
        padding: 0.1rem  0.3rem;
        background-color: #2980b9;
        color: white;
        border-radius: 4px;
        margin-top: 0.3rem;
        transition: all .3s;
    }
    button:first-child{
        margin-left: 0.3rem;
    }

    button:hover{
        box-shadow: 0 0 8px #3498db;
        transform: scale(0.98);
    }
</style>
